// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import { getDefaultStaticProps } from '../common/utils/utils';
import Loader from '../widgets/plug/Loader';
import { getLangsConnect } from '../common/utils/api';
import MainPage, { getStaticPropsFunction } from './[lang]/index'


//const App = () => <Loader />;
// export async function getStaticProps() {
//     const defaultProps = await getDefaultStaticProps()
//     return { props: { ...defaultProps } }
//}
export async function getStaticProps(){
    const langFetchRes = await getLangsConnect({ isDefault: true });
    const activeLangs = langFetchRes?.data || [];

    let isActiveLanguage = activeLangs[0];
    if (!isActiveLanguage){
        return { notFound: true, revalidate: 10 }
    }

    const lang = isActiveLanguage?.alias;

    return getStaticPropsFunction({ params: { lang } })
}

export default MainPage;